import { render, staticRenderFns } from "./ListClientNoTransaction.vue?vue&type=template&id=5d24b62c&scoped=true&"
import script from "./ListClientNoTransaction.vue?vue&type=script&lang=js&"
export * from "./ListClientNoTransaction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d24b62c",
  null
  
)

export default component.exports