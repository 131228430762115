<template>
  <b-row>
    <b-container fluid>
      <b-overlay
        :show="showLoading"
        :opacity="1"
        variant="light"
        rounded="sm"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <strong>Carregando Análise, Aguarde...</strong>
            <b-icon
              icon="stopwatch"
              font-scale="3"
              animation="cylon"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
            <b-spinner
              type="grow"
              variant="primary"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
          </div>
        </template>
        <b-card title="Análise de cobrança">
          <b-embed
            :src="iframeUrl"
            type="iframe"
            aspect="16by9"
            allowfullscreen
            lazy
          />
        </b-card>

      </b-overlay>
    </b-container>
  </b-row>
</template>
<script>
import {
  BContainer,
  BCard,
  BEmbed,
  BSpinner,
  BOverlay,
  BIcon,
  BRow,
} from 'bootstrap-vue'
import metaBase from '@/service/reports/reportsMetaBase'

export default {
  components: {
    BContainer,
    BCard,
    BEmbed,
    BSpinner,
    BOverlay,
    BIcon,
    BRow,
  },
  data() {
    return {
      iframeUrl: null,
      showLoading: false,
    }
  },
  mounted() {
    this.dashboard()
  },

  methods: {
    async dashboard() {
      this.iframeUrl = await metaBase(580)
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
